import { trigger, animate, style, group, animateChild, query, stagger, transition } from '@angular/animations';

export const routerTransition = trigger('routerTransition', [
  transition('* <=> *', [
    /* order */
    /* 1 */ query(':enter, :leave', style({ position: 'absolute', width: '100%', opacity: 0 }), { optional: true }),
    query(
      ':leave',
      [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate('0.25s ease-out', style({ transform: 'translateY(20%)', opacity: 0 }))
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        style({ transform: 'translateY(20%)', opacity: 0 }),
        animate('0.2s ease-out', style({ transform: 'translateY(0%)', opacity: 1 }))
      ],
      { optional: true }
    )
  ])
]);
export const routerTransitionLeft = trigger('routerTransitionLeft', [
  transition('* <=> *', [
    /* order */
    /* 1 */ query(':enter, :leave', style({ position: 'absolute', width: '100%', opacity: 0 }), { optional: true }),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0.175s cubic-bezier(.63,.61,.29,.96)', style({ transform: 'translateX(-25%)', opacity: 0 }))
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        style({ transform: 'translateX(-25%)', opacity: 0 }),
        animate('0.125s cubic-bezier(.63,.61,.29,.96)', style({ transform: 'translateX(0%)', opacity: 1 }))
      ],
      { optional: true }
    )
  ])
]);
